import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SideMenuModule} from '../side-menu/side-menu.module';
import {FieldEditorModule} from '../shared/components/field-editor/field-editor.module';

import {UserComponent} from './user.component';

import {FaIconLibrary, FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {faBuildingColumns, faCheckSquare, faComments, faCreditCard, faMoneyBillAlt, faNewspaper, faSync, faUserCheck, faUserXmark, faUsers} from '@fortawesome/free-solid-svg-icons';
import { ExternalLinkModule } from '../shared/external-link-icon/external-link-icon.module';
import { ColumnSorterModule } from '../shared/column-sorter/column-sorter.module';
import { ConfirmationModalModule } from '../../app/shared/confirmation-modal/confirmaton-modal.module';

@NgModule({
  declarations: [ UserComponent ],
  imports: [
    RouterModule.forChild([{ path: '', component: UserComponent }]),
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SideMenuModule,
    FieldEditorModule,
    FontAwesomeModule,
    ExternalLinkModule,
    ColumnSorterModule,
    ConfirmationModalModule
  ],
  exports: [ UserComponent ]
})

export class UserModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(faSync, faUsers, faMoneyBillAlt, faNewspaper, faCreditCard, faComments, faBuildingColumns, faCheckSquare, faUserCheck, faUserXmark);
  }
}
