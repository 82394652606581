<notd-boffice-side-menu></notd-boffice-side-menu>
<div class="container-fluid">
  <h3 class="sub-header" *ngIf="user">
    User profile: <strong>{{user?.firstName}} {{user?.lastName}}</strong> <span class="small color-carmine"> (id: {{user?.id}})</span>
    <fa-icon [icon]="['fas', 'users']"></fa-icon>
    <external-link-icon
      *ngIf="externalLink?.user"
      [label]="'Show User profile on Notd'"
      [title]="'Show user profile on Notd'"
      [link]="externalLink.user"></external-link-icon>
  </h3>
  <div class="loading-spinner-holder" *ngIf="loadingProfile">
    <fa-icon [icon]="['fas', 'sync']" [spin]="true" [size]="'lg'"></fa-icon>
  </div>
  <div class="profile-wrapper" *ngIf="!loadingProfile">
    <div class="profile-info">
      <div class="avatar">
        <img *ngIf="user?.profileImageUrl" class="rounded" [src]="user.profileImageUrl"/>
        <img *ngIf="!user?.profileImageUrl" class="rounded" [src]="'assets/svg/user.svg'"/>
        <div class="buttons">
          <button class="btn red" (click)="deleteUser()">Delete user</button>
          <button class="btn mt-5" [ngClass]="{'black' : user['banned'], 'blue' : !user['banned']}" (click)="banUser()">{{user['banned'] ? 'Unban' : 'Ban'}} this user</button>
        </div>
        <div class="sub-left-section" *ngIf="user">
          <h3 class="sub-header xs-size mt-10">
            Spending / Earnings
            <fa-icon [icon]="['fas', 'money-bill-alt']"></fa-icon>
          </h3>
          <ul>
            <li>
              <a [routerLink]="['/user', user.id, 'earnings']" title="Show user earnings">Show user earnings</a>
            </li>
            <li>
              <a [routerLink]="['/user', user.id, 'spendings']" title="Show user spendings">Show user spendings</a>
            </li>
          </ul>
        </div>
        <div class="sub-left-section text-center" *ngIf="user">
          <h3 class="sub-header xs-size mt-10">
            Verify user account
            <fa-icon [icon]="['fas', 'check-square']"></fa-icon>
          </h3>
          <div classv="verification-content">
            <div class="verification-content__states">
              <span
                *ngIf="user.verifiedId > 0"
                class="verification-content__states__state verified">
                <fa-icon [icon]="['fas', 'user-check']"></fa-icon>
                Account verified
              </span>
              <span
                *ngIf="user.verifiedId === 0"
                class="verification-content__states__state not-verified">
                <fa-icon [icon]="['fas', 'user-xmark']"></fa-icon>
                Account not verified
              </span>
            </div>
            <button class="notd__button--v2 button--black verification-content__verify-button" (click)="showVerificationModal()">Change verify status</button>
          </div>
        </div>
      </div>
      <div class="profile-details">
        <div class="text-label mb-10">
          <label>Status:</label>
          <span class="text">
            <span class="tag" [ngClass]="{'active' : user['admin'] ? true : false}">admin</span>
            <span class="tag" [ngClass]="{'active' : user['banned'] ? true : false}">banned</span>
            <span class="tag" [ngClass]="{'active' : user['accountClosed'] ? true : false}">account closed</span>
            <span class="tag" [ngClass]="{'active' : user['notifyFollow'] ? true : false}">notify follow</span>
            <span class="tag" [ngClass]="{'active' : user['notifyMyComms'] ? true : false}">notify my comments</span>
            <span class="tag" [ngClass]="{'active' : user['notifyMyPosts'] ? true : false}">notify my posts</span>
          </span>
        </div>
        <div class="text-label">
          <label>Account created:</label>
          <span class="text">{{user.created | date: 'dd/MM/yyyy'}}</span>
        </div>
        <div class="text-label">
          <label>User name:</label>
          <span class="text">{{user.firstName}} {{user.lastName}}</span>
        </div>
        <div class="text-label">
          <label>User e-mail:</label>
          <span class="text">{{user.email1}}</span>
        </div>
        <div class="text-label">
          <label>System user name:</label>
          <span class="text">{{user.userName ? user.userName : 'no system user name'}}</span>
        </div>
        <div class="text-label">
          <label>Display name:</label>
          <field-editor [sourceModel]="user.displayName" (changeValue)="changeDisplayName($event)"></field-editor>
        </div>
        <div class="text-label">
          <label>Funds:</label>
          <span class="text" *ngIf="user && user.funds">{{user.funds['PROD'] / 10000 | currency:'USD':'symbol'}}</span>
          <span class="text" *ngIf="user && !user.funds">{{0 | currency:'USD':'symbol'}}</span>
        </div>
        <div class="text-label">
          <label>Tax document:</label>
          <span class="text" *ngIf="!user['taxDoc']">none</span>
          <a class="text link" (click)="getTaxDoc()" *ngIf="user['taxDoc']">Click here to see tax document</a>
        </div>
        <div class="text-label">
          <label>Identity document:</label>
          <span class="text" *ngIf="!user['identityDoc']">none</span>
          <a class="text link" (click)="getIdentityDoc()" *ngIf="user['identityDoc']">Click here to see identity document</a>
        </div>
      </div>
    </div>

    <div class="subcontent-wrapper mt-20">
      <h3 class="sub-header x-size">
        Banking Info
        <fa-icon [icon]="['fas', 'building-columns']"></fa-icon>
      </h3>
      <section *ngIf="bankingInfo.loading" class="bo__loading banking-loader">
        <span class="bo__loading__spinner"></span>
        <p class="bo__loading__label">Loading banking info...</p>
      </section>
      <table *ngIf="!bankingInfo.loading && bankingInfo.list.length" class="bo__table text--centered">
        <thead>
          <tr>
            <th>
              <column-sorter [text]="'Account Holder'" [list]="bankingInfo.list" [field]="'accountHolder'"></column-sorter>
            </th>
            <th>
              <column-sorter [text]="'Account Number'" [list]="bankingInfo.list" [field]="'accountNumber'"></column-sorter>
            </th>
            <th>
              <column-sorter [text]="'Account Last 4-dig'" [list]="bankingInfo.list" [field]="'accNumLast4'"></column-sorter>
            </th>
            <th>
              <column-sorter [text]="'Name'" [list]="bankingInfo.list" [field]="'name'"></column-sorter>
            </th>
            <th>
              <column-sorter [text]="'Routing Number'" [list]="bankingInfo.list" [field]="'routingNumber'"></column-sorter>
            </th>
            <th>
              <column-sorter [text]="'Transfer Method'" [list]="bankingInfo.list" [field]="'transferMethod'"></column-sorter>
            </th>
            <th>
              <column-sorter [text]="'User'" [list]="bankingInfo.list" [field]="'userId'"></column-sorter>
            </th>
            <th>
              <column-sorter [text]="'Created'" [list]="bankingInfo.list" [field]="'created'"></column-sorter>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let bi of bankingInfo.list">
            <td>
            <td>
              {{ bi.accountHolder || '-' }}
            </td>
            <td>
              {{ bi.accountNumber || '-'  }}
            </td>
            <td>
              {{ bi.accNumLast4 || '-'  }}
            </td>
            <td>
              {{ bi.name || '-'  }}
            </td>
            <td>
              {{ bi.routingNumber || '-'  }}
            </td>
            <td>
              {{ bi.transferMethodTitle || '-'  }}
            </td>
            <td>
              <a [routerLink]="['/user', bi.userId]" *ngIf="bi.user">{{bi.user.displayName || bi.user.firstName + ' ' + bi.user.lastName}}</a>
            </td>
            <td>
              {{ bi.created | date: 'MMM dd, yyyy' }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="streams-wrapper mt-20">
      <h3 class="sub-header x-size">
        User streams <span class="small color-carmine"> ({{userStreams?.length}})</span>
        <fa-icon [icon]="['fas', 'newspaper']"></fa-icon>
      </h3>
      <table class="table notd-backoffice-table" *ngIf="!loadingUserStreams && userStreams.length > 0">
        <thead class="thead-dark">
        <tr>
          <th scope="col">#</th>
          <th scope="col">Title</th>
          <th scope="col">Notd stream external profile</th>
          <th scope="col" class="text-center">Notes</th>
          <th scope="col" class="text-center">Followers</th>
          <th scope="col" class="text-center">Subs</th>
          <th scope="col" class="text-center">Purchases</th>
          <th scope="col" class="text-center">Payments</th>
          <th scope="col" class="text-center">Views</th>
          <th scope="col" class="text-center">Rating</th>
          <th scope="col" class="text-center">Created</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let stream of userStreams; let i = index">
          <td>{{i+1}}</td>
          <td>
            <a [routerLink]="['/stream', stream.id]" class="link" title="{{stream.name}}">{{stream.name}}</a>
          </td>
          <td>
            <external-link-icon
              *ngIf="stream.externalLink"
              [title]="'View stream on notd'"
              [link]="stream.externalLink"></external-link-icon>
          </td>
          <td class="text-center">
            {{stream.numPosts}}
          </td>
          <td class="text-center">{{stream.followers}}</td>
          <td class="text-center">{{stream.numSubs}}</td>
          <td class="text-center">{{stream['numPurchases']}}</td>
          <td class="text-center">
            <fa-icon *ngIf="!stream.payments" [icon]="['fas', 'sync']" [spin]="true"></fa-icon>
            <a class="link" *ngIf="stream?.payments" (click)="showStreamPayments(stream)">show details (<strong>{{stream?.payments.length}}</strong>)</a>
          </td>
          <td class="text-center">{{stream.numViews}}</td>
          <td class="text-center">{{stream.ratingAvg | number:'1.2-2'}}</td>
          <td class="text-center">{{stream.created | date: 'dd/MM/yyyy'}}</td>
        </tr>
        </tbody>
      </table>
      <div class="text-center" *ngIf="loadingUserStreams">
        <fa-icon [icon]="['fas', 'sync']" [spin]="true" [size]="'lg'"></fa-icon>
      </div>
    </div>

    <div class="payouts-wrapper mt-20">
      <h3 class="sub-header x-size">
        User payouts <span class="small color-carmine"> ({{userPayouts?.length}})</span>
        <fa-icon [icon]="['fas', 'credit-card']"></fa-icon>
      </h3>
      <table class="table notd-backoffice-table small" *ngIf="!loadingUserPayouts && userPayouts.length > 0">
        <thead class="thead-dark">
        <tr>
          <th scope="col" class="align-middle text-center">#</th>
          <th scope="col" class="align-middle">Bank info</th>
          <th scope="col" class="align-middle text-center">Destination</th>
          <th scope="col" class="align-middle text-center">Status</th>
          <th scope="col" class="align-middle text-center">Amounts<br /><span class="small">(req / sent)</span></th>
          <th scope="col" class="align-middle text-center">Request Ip Address</th>
          <th scope="col" class="align-middle text-center">Created</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let payout of userPayouts; let i = index" (click)="openPayoutDetails(payout)">
          <td>{{i+1}}</td>
          <td class="small">
            <span class="line"><strong>bank name:</strong> {{payout.bank.name}}</span>
            <span class="line"><strong>iban:</strong> {{payout.bank.iban || 'none'}}</span>
            <span class="line"><strong>swift:</strong> {{payout.bank.swift || 'none'}}</span>
            <span class="line"><strong>account holder:</strong> {{payout.bank.accountHolder}}</span>
            <span class="line"><strong>account number:</strong> {{payout.bank.accountNumber}}</span>
            <span class="line" *ngIf="payout.bank.routingNumber">
              <strong>routing number:</strong>
              {{payout.bank.routingNumber}}
            </span>
            <span class="line"><strong>transfer method:</strong> {{payout.bank.transferMethod}}</span>
          </td>
          <td class="text-center color-carmine strong">{{payoutDestinationType[payout.destType]}}</td>
          <td class="text-center color-blue strong">{{payoutStatus[payout.status]}}</td>
          <td class="text-center">
            {{payout.amountReq / 100}} / {{payout.amountSent / 100}}
          </td>
          <td class="text-center">{{payout.reqIpAddress}}</td>
          <td class="text-center">{{payout.created | date: 'dd/MM/yyyy'}}</td>
        </tr>
        </tbody>
      </table>
      <div class="text-center" *ngIf="loadingUserPayouts">
        <fa-icon [icon]="['fas', 'sync']" [spin]="true"></fa-icon>
      </div>
    </div>

    <div class="comments-wrapper mt-20">
      <h3 class="sub-header  x-size">
        User comments <span class="small color-carmine"> ({{userComments?.length}})</span>
        <fa-icon [icon]="['fas', 'comments']"></fa-icon>
      </h3>
      <div class="comments-list" *ngIf="!loadingUserComments && userComments.length > 0">
        <div class="comment" *ngFor="let comment of userComments">
          <h2 class="note-title">
            <a class="note-name" (click)="openAppNote(comment.noteId, comment.noteName)" title="{{comment.noteName}}">{{comment.noteName}}</a>
            <a class="stream-name" (click)="openAppStream(comment.streamId, comment.streamName)" title="{{comment.streamName}}">{{comment.streamName}}</a>
            <span class="date">{{comment.created | date: 'dd/MM/yyyy'}}</span>
          </h2>
          <p class="comment-text">{{comment.comment}}</p>
          <div class="likes">
            <span>like: {{comment.likes}}</span> / <span>dislike: {{comment.dislikes}}</span>
          </div>
        </div>
      </div>
      <div class="text-center" *ngIf="loadingUserComments">
        <fa-icon [icon]="['fas', 'sync']" [spin]="true"></fa-icon>
      </div>
    </div>
  </div>
</div>

<ng-template #streamPayments let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Stream payments status</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <h3 class="sub-header x-size">
      {{streamPaymentsDetail.name}} <span class="small color-carmine"> ({{streamPaymentsDetail.payments?.length}})</span>
      <fa-icon [icon]="['fas', 'credit-card']"></fa-icon>
    </h3>
    <table class="table notd-backoffice-table small">
      <thead class="thead-dark">
      <tr>
        <th scope="col">#</th>
        <th scope="col" class="text-center">Start balance</th>
        <th scope="col" class="text-center">End balance</th>
        <th scope="col" class="text-center">Sold Post Count</th>
        <th scope="col" class="text-center">Sold Sub Count</th>
        <th scope="col" class="text-center">Total sold</th>
        <th scope="col" class="text-center">User earn</th>
        <th scope="col" class="text-center">Created</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let payment of streamPaymentsDetail.payments; let i = index">
        <td>{{i+1}}</td>
        <td class="text-center">{{payment['startBalance'] / 10000 | currency:'USD':'symbol'}}</td>
        <td class="text-center">{{payment['endBalance'] / 10000 | currency:'USD':'symbol'}}</td>
        <td class="text-center">{{payment['soldPostCount']}}</td>
        <td class="text-center">{{payment['soldSubCount']}}</td>
        <td class="text-center">{{payment['totalSold']}}</td>
        <td class="text-center">{{payment['usrEarns_cc'] / 10000 | currency:'USD':'symbol'}}</td>
        <td class="text-center">{{payment['created'] | date: 'dd/MM/yyyy'}}</td>
      </tr>
      </tbody>
    </table>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" aria-label="Close" (click)="c('Cross click')">Close</button>
  </div>
</ng-template>

<ng-template #verifyUserModal let-c="close" let-d="dismiss" class="notd__modal">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Verify user account</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <section class="modal-body">
    <form novalidate [formGroup]="verifyUserForm">
      <section class="verify-user-modal__form">
        <div class="verify-user-modal__form__input-wrapper">
          <label>Set verification user state:</label>
          <div class="verify-user-modal__form__input-wrapper__button-join">
            <button
              type="button"
              class="notd__button--v2 button--white"
              [ngClass]="{'button--selected': verifyUserForm.get('verifiedState').value > 0 }"
              aria-label="Close"
              (click)="changeVerifyState(1)">Verified</button>
            <button
              type="button"
              class="notd__button--v2 button--white"
              [ngClass]="{'button--selected': verifyUserForm.get('verifiedState').value === 0 }"
              aria-label="Close"
              (click)="changeVerifyState(0)">Not Verified</button>
          </div>
        </div>
        <div class="verify-user-modal__form__input-wrapper">
          <label>Set verified user name:</label>
          <input
            type="text"
            formControlName="verifiedName"
            class="verify-user-modal__form__input-wrapper__input notd__input"
            placeholder="Verified User Name">
            <span
              class="verify-user-modal__form__input-wrapper__error"
              *ngIf="verifyUserForm.get('verifiedName').touched && verifyUserForm.get('verifiedName').hasError('required')">
                  <svg enable-background="new 0 0 512 512" height="512" viewBox="0 0 512 512" width="512" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="256" x2="256" y1="512" y2="0"><stop offset="0" stop-color="#fd3a84"/><stop offset="1" stop-color="#ffa68d"/></linearGradient><linearGradient id="lg1"><stop offset="0" stop-color="#ffc2cc"/><stop offset="1" stop-color="#fff2f4"/></linearGradient><linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="256" x2="256" xlink:href="#lg1" y1="301" y2="91"/><linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="256" x2="256" xlink:href="#lg1" y1="421" y2="331"/><g><g><path d="m355.826 512h-199.651c-3.979 0-7.794-1.58-10.606-4.394l-141.175-141.174c-2.814-2.813-4.394-6.628-4.394-10.606v-199.652c0-3.978 1.58-7.793 4.394-10.606l141.174-141.174c2.813-2.814 6.628-4.394 10.607-4.394h199.651c3.979 0 7.794 1.581 10.606 4.394l141.174 141.174c2.813 2.813 4.394 6.628 4.394 10.606v199.651c0 3.978-1.58 7.793-4.394 10.606l-141.173 141.175c-2.813 2.813-6.628 4.394-10.607 4.394z" fill="url(#SVGID_1_)"/></g><g id="Error"><g><path d="m256 301c-24.813 0-45-20.187-45-45v-120c0-24.813 20.187-45 45-45s45 20.187 45 45v120c0 24.813-20.187 45-45 45z" fill="url(#SVGID_2_)"/></g><g><path d="m256 421c-24.813 0-45-20.187-45-45s20.187-45 45-45 45 20.187 45 45-20.187 45-45 45z" fill="url(#SVGID_3_)"/></g></g></g></svg>
                  Please provide verified user name.
            </span>
        </div>
      </section>
    </form>
  </section>
  <div class="modal-footer">
    <button type="button" class="notd__button--v2 button--black" aria-label="Close" (click)="saveVerifyUserForm()">Save</button>
    <button type="button" class="notd__button--v2 button--black" aria-label="Close" (click)="c('Cross click')">Close</button>
  </div>
</ng-template>

